'use client';

import {
  Button,
  Container,
  Heading,
  Flex,
  Text,
  Spacer,
} from '@backyard-ui/core';

import * as tracker from '@/infra/tracking/tracker';

function NotFound() {
  return (
    <section className="py-20" data-testid="not-found-error">
      <Container size="6xl">
        <Flex direction="column" justify="center" align="center">
          <Heading>Desculpe, não localizamos a página pesquisada.</Heading>
          <Spacer size="2" axis="vertical" />
          <Text>
            Volte para a página inicial e continue pesquisando e se inspirando
            ;)
          </Text>
          <Spacer size="10" axis="vertical" />
          <Button onClick={() => tracker.onError404PageActionClick()} asChild>
            <a href="/">Voltar para a página inicial</a>
          </Button>
        </Flex>
      </Container>
    </section>
  );
}

export default NotFound;
